import React from 'react';
import { File } from '@model/generated/index';
import { Layout } from '@components/global/Layout';
import { graphql, PageProps } from 'gatsby';
import { navigationItemsProjects } from '@model/projekte/navigationItemsProjects';
import { Meta } from '@components/global/Meta';

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "projekte/wunder_welle/image1.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
      publicURL
    }
  }
`;

type DataProps = {
  image1: File;
};

type Props = PageProps<DataProps>;

const Page: React.FC = () => (
  <Layout title="Projekte" navigationItems={navigationItemsProjects}>
    <div className="flex">
      <div className="flex-auto p-32">
        <div className="flex-1 mb-16 pt-32">
          <h3>Guido Meyer</h3>
          <p>
            Hier erscheinen satirische Audio-Begleitprogramme zu den
            Schwerpunktthemen von Ausstellungen, Performances und anderen
            Kunstevents der Wunderkammer.
          </p>
        </div>
        <iframe
          className="mb-16"
          title="Antenne Tanne"
          width="100%"
          height="166"
          scrolling="no"
          frameBorder="no"
          allow="autoplay"
          src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1107000808&color=%23bbcdc8&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
        />
        <iframe
          title="Wunderwelle Wortfindungsamt"
          width="100%"
          height="166"
          scrolling="no"
          frameBorder="no"
          allow="autoplay"
          src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1214033161&color=%23bbcdc8&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true "
        />
      </div>
      <h1 className="pb-32" style={{ writingMode: 'vertical-rl' }}>
        Wunder Welle
      </h1>
    </div>
  </Layout>
);

export const Head: React.FC<Props> = (props) => {
  return (
    <Meta
      description="Hier erscheinen satirische Audio-Begleitprogramme zu den
      Schwerpunktthemen von Ausstellungen, Performances und anderen
      Kunstevents der Wunderkammer."
      publicURL={props.data.image1.publicURL}
      title="Wunder Welle"
    />
  );
};

export default Page;
